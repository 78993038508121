import React, { Component } from "react";
import { Container, Row, Col, FormGroup, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Animated } from "react-animated-css";

//Import Section Title
import SectionTitle from "../common/section-title";

class GetInTouch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      email: "",
      subject: "",
      comments: "",
      msgSendSuccess: false,
    };
  }

  handleSubmit = () => {
    let emailPattern = new RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/);

    if (
      this.state.firstname !== "" &&
      this.state.email !== "" &&
      emailPattern.test(this.state.email) &&
      this.state.comments !== ""
    ) {

      const api_key = 'a987cc1542f5f1e87058cac348b520b6-bbbc8336-99003e9a';
      const domain = 'letter.klipshop.com';

      const messageData = {
        from: 'loosefill.lt <reviews@letter.klipshop.com>',
        to: 'aivaras@klipshop.lt, info@loosefill.lt',
        subject: 'Užklausa iš vartotojo: '+this.state.firstname+' - '+this.state.email,
        text: 'Užklausa: '+this.state.comments,
      };

      const mailgun = require('mailgun-js')({apiKey:api_key,domain:domain, host: 'api.eu.mailgun.net'});

      var self = this;
      mailgun.messages().send(messageData,function(error,res){

        if(error){
          alert('Message not send')
          return
        }


        self.setState({ msgSendSuccess: true });
        self.myFormRef.reset();

        const element = document.getElementById('contact');
        element.scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
          self.setState({ msgSendSuccess: false });
        }, 5000);

      })

    }
  };

  onInputChangeHandlar = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    this.setState({ [name]: value });
  };

  render() {
    return (
      <React.Fragment>
        <section className="section " id="contact">
          <Container>
            <SectionTitle title1="Kontaktai" title2="" />

            <Row className="mt-4 pt-4 contact-info-block">
              <Col lg="6">
                <h4>Įmonės rekvizitai</h4>
                <ul>
                  <li>
                    <strong>Įmonės pavadinimas:</strong> GSS Baltic, UAB
                  </li>
                  <li>
                    <strong>Įmonės kodas:</strong> 304071364
                  </li>
                  <li>
                    <strong>PVM mokėtojo kodas:</strong> LT100009551614
                  </li>
                  <li>
                    <strong>Registracijos adresas:</strong> Vilniaus g. 215, Šiauliai
                  </li>
                  <li>
                    <strong>Korespondencija:</strong> Dvaro g. 64A, Šiauliai
                  </li>
                  <li>
                    <strong>Atsiskaitomoji sąskaita:</strong> LT983540023810171449
                  </li>
                  <li>
                    <strong>Bankas:</strong> SEB Bankas
                  </li>
                  <li>
                    <strong>Registro tvarkytojas:</strong> VĮ Registrų centras
                  </li>
                </ul>
              </Col>
              <Col lg="6">
                <h4>Klientų aptarnavimas</h4>
                <ul>
                  <li>
                    <strong>Telefonas:</strong> <a href={'tel:+370 698 20093'}>+370 698 20093</a>
                  </li>
                  <li>
                    <strong>Klausimai apie prekes:</strong> <a href={'mailto:info@loosefill.lt'}>info@loosefill.lt</a>
                  </li>
                  <li>
                    <strong>Darbo laikas:</strong> I - V 09:00 - 17:00 val.
                  </li>
                </ul>
              </Col>
            </Row>

            <div className="custom-form mt-4 pt-4">
              <div id="message">
                {this.state.msgSendSuccess ? (
                  <Animated
                    animationIn="bounceIn"
                    animationOut="flash"
                    animationInDuration={1000}
                    animationOutDuration={1000}
                    isVisible={true}
                  >
                    <fieldset>
                      <div id="success_page">
                        <h3>Laiškas išsiųstas</h3>
                        <p>
                          Ačiū <strong>{this.state.firstname}</strong>,
                          Jūsų užklausa išsiųsta sėkmingai!
                        </p>
                      </div>
                    </fieldset>
                  </Animated>
                ) : null}
              </div>
              <AvForm
                name="contact-form"
                id="contact-form"
                ref={(el) => (this.myFormRef = el)}
                onSubmit={(e) => this.handleSubmit(e)}
              >
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label for="name">Vardas</Label>
                      <AvField
                        name="firstname"
                        placeholder="Vardas arba įmonės pavadinimas"
                        type="text"
                        errorMessage="Įveskite vardą"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                        onChange={(e) => this.onInputChangeHandlar(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label for="email">El.paštas</Label>
                      <AvField
                        name="email"
                        placeholder="El.paštas"
                        type="email"
                        errorMessage="Įveskite el.paštą"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          email: { value: true },
                        }}
                        onChange={(e) => this.onInputChangeHandlar(e)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label for="comments">Užklausa</Label>
                      <AvField
                        type="textarea"
                        name="comments"
                        id="comments"
                        rows="4"
                        className="form-control"
                        errorMessage="Įveskite užklausa"
                        validate={{ required: { value: true } }}
                        onChange={(e) => this.onInputChangeHandlar(e)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" className="text-right">
                    <input
                      type="submit"
                      id="submit"
                      name="send"
                      className="submitBnt submitBntContact btn btn-primary btn-custom"
                      value="Siųsti"
                    />
                    <div id="simple-msg"></div>
                  </Col>
                </Row>
              </AvForm>
            </div>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default GetInTouch;
