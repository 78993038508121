import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Footer link
import FooterLinks from "./footer-links";

class Footer extends Component {
    state = {
        footerItems : [
            { title : "about Linexon" ,
                links : [
                    { linkTitle : "About Us", link : "#" },
                    { linkTitle : "Press", link : "#" },
                    { linkTitle : "Job Opening", link : "#" },
                    { linkTitle : "Terms", link : "#" },
                    { linkTitle : "Privacy", link : "#" },
                ]
            },
            { title : "resaurses" ,
                links : [
                    { linkTitle : "Blog", link : "#" },
                    { linkTitle : "Help Center", link : "#" },
                    { linkTitle : "Academy", link : "#" },
                    { linkTitle : "Video Terms", link : "#" },
                    { linkTitle : "Monitoring Grader", link : "#" },
                    { linkTitle : "Linexon API", link : "#" },
                ]
            },
            { title : "more info" ,
                links : [
                    { linkTitle : "Pricing", link : "#" },
                    { linkTitle : "For Marketing", link : "#" },
                    { linkTitle : "For CEOs", link : "#" },
                    { linkTitle : "For Agencies", link : "#" },
                    { linkTitle : "Our Apps", link : "#" },
                ]
            },
        ]
    }
    render() {
        return (
            <React.Fragment>
        <section className="footer-block bg-light">
            <Container>
                <Row>
                    <Col lg="12">
                        <div className={'footer-text'}>Sekite mus socialiniuose tinkluose</div>
                        <ul className="footer-icons list-inline mt-4">
                            <li className="list-inline-item"><a href="https://www.facebook.com/profile.php?id=100089218645857" className=""><i className="mdi mdi-facebook"></i></a></li>
                            <li className="list-inline-item"><a href="https://www.instagram.com/loosefill/?igshid=YmMyMTA2M2Y%3D&fbclid=IwAR2q_g1Tz1HpUgtKYkT7SvH4rlkeeWhsWYMDBVb-uownpbW5Pl64B8VHS0c" className=""><i className="mdi mdi-instagram"></i></a></li>
                            <li className="list-inline-item"><a href="https://www.tiktok.com/@loosefill" className=""><i className="mdi mdi-music-note"></i></a></li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </section>
                {/* Render footer links */}
                <FooterLinks/>
            </React.Fragment>
        );
    }
}

export default Footer;
